import authRoles from '@/app/auth/authRoles';
import React from 'react';
const UserManagementConfig = {
	settings: {
		layout: {
			config: {},
		},
	},
	routes: [
		{
			path: '/user-management',
			component: React.lazy(() => import('./UserManagement')),
		},
	],
	auth: authRoles.admin,
};

export default UserManagementConfig;
