import SchoolsConfig from './schools/SchoolsConfig';
import AdminDashboardConfig from './home/AdminDashboardConfig';
import SuperAdminProfileConfig from './profile/profileConfig';
import CompanyReportsConfig from './companyreports/CompanyReportsConfig';
import UserManagementConfig from './usermanagement/UserManagementConfig';

const SuperAdminConfig = [
	SchoolsConfig,
	AdminDashboardConfig,
	SuperAdminProfileConfig,
	CompanyReportsConfig,
	UserManagementConfig,
];

export default SuperAdminConfig;
